// 收藏
<template>
    <div class="content">

        <Top></Top>

        <!-- 主体部分 -->
        <div class="main font-color-wirte">
            <div class="main-left">
                <div class="title">历史收藏</div>
                <div class="bottom">
                    <div class="iconfont icon-lishi"></div>
                <div class="record">播放记录</div>
                </div>
            </div>
            <div class="main-right">
                <!-- ul -->
                <div class="list">
                    <!-- li -->
                    <div class="video-img" v-for="(item, i) in [1,2,3,4,5,6,7,8,9]" :key="i">
                        <!-- 常规展示 -->
                        <div v-if="i < 8">
                            <img src="https://img0.baidu.com/it/u=422078137,1307526884&fm=253&fmt=auto&app=120&f=JPEG?w=1280&h=800" alt="" />
                            <div class="desic">正山小种</div>
                        </div>
                        <!-- 查看更多 -->
                        <div class="more" v-else>
                            <div class="more-img"></div>
                            <div class="more-text">
                                <span>登录查看</span>
                                <span>更多记录</span>
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
        </div>
    </div>
</template>


<script>
import Top from '../components/common/Top.vue'
export default {
    components: {
        Top
    },
    data() {
        return {

        }
    }
}
</script>


<style lang="less" scoped>
@import './css/collect.less';
</style>